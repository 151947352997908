"use client";

import {Button, Result} from "antd";
import type {NextPage} from "next";
import {useRouter} from "next/navigation";
import React, {useCallback} from "react";

const NotFound: NextPage = () => {
  const router = useRouter();

  const handleClick = useCallback(() => {
    router.replace("/");
  }, [router]);

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={handleClick}>
          Back Home
        </Button>
      }
    />
  );
};

export default NotFound;
